<template>
  <inline-style>{{ inlinestyle }}</inline-style>
</template>

<script>
import InlineStyle from "./InlineStyle.vue";
import stlyleNissan from "../globals/styleNissan.css.txt";
import styleUnameit from "../globals/styleUnameit.css.txt";
import styleRenault from "../globals/styleRenault.css.txt";
import styleMitsubishi from "../globals/styleMitsubishi.css.txt";

export default {
  computed: {
    importeur() {
      return this.$store.getters.importeurNaam || "";
    },
    inlinestyle() {
      if (this.importeur === "Nissan") return stlyleNissan;
      if (this.importeur === "Unameit") return styleUnameit;
      if (this.importeur === "Renault") return styleRenault;
      if (this.importeur === "Mitsubishi") return styleMitsubishi;
      return "";
    }
  },
  components: {
    InlineStyle
  }
};
</script>
