import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/Home.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../pages/Login.vue")
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("../pages/Logout.vue")
    },
    {
      path: "/changepassword",
      name: "changepassword",
      component: () => import("../pages/ChangePassword.vue")
    },
    {
      name: "users",
      path: "/users/:id?",
      component: () => import("../pages/users/UsersContainer.vue")
    },
    {
      path: "/leads",
      name: "leads",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../pages/dashboard/Dashboard.vue")
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("../pages/orders/Orders.vue")
    },
    {
      path: "/reports",
      name: "reports",
      component: () => import("../pages/rapportage/Reports.vue")
    },
    {
      path: "/configuration",
      name: "configuration",
      component: () => import("../pages/Configuration.vue")
    },
    {
      path: "/orders/grid",
      name: "ordersgrid",
      component: () => import("../pages/orders/OrdersPivotPage.vue")
    },
    {
      path: "/leads/grid",
      name: "leadsgrid",
      component: () => import("../pages/dashboard/LeadsPivotPage.vue")
    },
    { path: "*", redirect: "/" }
  ]
});
