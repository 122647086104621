export default {
  configuration: "Configuration",
  switchLanguage: "Switch language",
  changepassword: "Change password",
  menuoverzicht: "My overview",
  beheer: "Admin",
  searchPlaceholder: "Search...", 
  email: "Email address",
  emailshort: "Email",
  name: "Name",
  password: "Password",
  changepasswordold: "Old password",
  changepasswordnew: "New password",
  changepasswordconfirm: "Confirm new password",   
  logininvalid: "Invalid email or password",
  login: "Login", 
  details: "Details",
  select: "Select",
  sortMostRecent: "Most recent",
  sortTitle: "Title",
  filter: "Filter settings",
  save: "Save",
  cancel: "Cancel",
  active: "Active",
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  months: "months",
  minute: "minute",
  hour: "hour",
  day: "day",
  week: "week",
  month: "month",
  close: "Close",
  undo: "undo action",
  insert: "Insert",
  collapse: "Collapse",
  expand: "Expand",
  send: "Send",
  logout: "Log out",
  application: "Application",
  settings: "Settings",
  rapportage: "Report",
  dashboard: "Dashboard",
  period: "Period",
  fromperiod: "From",
  toperiod: "To",
  compareperioddata: "Compare with another period",
  fromperiodcompare: "From",
  toperiodcompare: "To",
  region: "Region",
  regions: "Regions",
  allregion: "All regions",
  autobedrijf: "Dealer",
  allautobedrijf: "All dealers",
  branch: "Location",
  allbranch: "All locations",
  nobranch: "No location",
  leadkind: "Kind of lead",
  allleadkinds: "All kinds of leads",
  leadtype: "Lead type",
  allleadtypes: "All leadtypes",
  widget1: "Sales<br>open leads",
  widget2: "Sales<br>conversion rate",
  widget3: "After sales<br>open leads",
  widget4: "After sales<br>conversion rate",  
  prefixConversion: "National ", 
  open: "Open",
  new: "New",
  inbehandeling: "In progress",
  verstreken: "Deadline expired",  
  afgerond: "Closed",
  succesvol: "Successful",
  verwijderd: "Deleted",
  gemist: "Missed",
  conversieratio: "Conversion rate",
  conversieratiosmall: "Conv. rate",
  leads: "Leads",
  leadsgrid: "Leads grid",
  orders: "Orders",
  pivotgrid: "Pivot grid",
  aantalorders: "Number of orders",
  orderratio: "Order rate",
  orderratiosmall: "Order rate",
  deadlinegehaald: "Within deadline",  
  deadlinegehaaldsmall: "Within deadl.",  
  total: "Total",
  beheertitel: "Users",
  userdetailitel: "User details",
  showinactiveusers:"Show inactive",
  autorisatie:"Authorisation",
  timefilters: {
    custom: "Custom range",
    today: "Today",
    yesterday: "Yesterday",
    thisweek: "This week",
    lastweek: "Last week",
    thismonth: "This month",
    lastmonth: "Last month",
    thisquarter: "This quarter",
    lastquarter: "Last quarter",
    thisyear: "This year",
    lastyear: "Last year"
  },
  administrator:"Admin",
  passwordTooWeak: "Password is too weak",
  passwordTooShort: "Password is too short (min. 8 characters)",
  invalidinput: "One or more invalid input detected",
  uniqueInput: "Email address is already in use",
  generalerror: "Unknown error occured",
  emailInvalid: "Email address is invalid",
  passwordIncorrect:"Password is incorrect",  
  passwordInequal:"Passwords are inequal", 
  models:"Model", 
  modelyear: "Model year",
  allmodels:"All models", 
  allmodelyears: "All model years",
  dealers:"Dealer",
  alldealers:"All dealers",  
  noauthorizations:"Insufficient authorizations",
  rapportageleads:"Reporting leads",
  rapportageorders:"Reporting orders",
  rapportagedealerleads:"Reporting dealer leads",
  leadtypereport:"Lead type",
  leadtypesales:"Sales",
  leadtypeaftersales:"After sales",
  leadtypeboth:"All lead types",
  downloadstart: "Your report will be downloaded. If the download doesn't start automatically:",
  downloadtext: "click here to download the report.",
  requestreport: "Request report",
  unknown: "Unknown",
  carkind: "Kind of car",
  allcarkinds: "All kinds of cars",
  infoautosoorten: "This concerns the desired type of car and only affects the sales data.",
  autosoorten: {
    unknown: "Unknown",
    new: "New",   
    occasion: "Used"
  },
  leadtypes: {
    unknown: "Unknown",
    sales: "Sales",   
    aftersales: "Aftersales"
  },
  templatetext: "Template",
  selecttemplate: "Select template...",
  publicmessage: "Is public",
  renametemplate: "Rename",
  removetemplate: "Remove",
  savetemplate: "Save",
  saveastemplate: "Save as",
  ownedprivate: "Owned private templates",
  ownedshared: "Owned shared templates",
  notownedshared: "Shared public templates",
  templateconfirm: "Confirm",
  templateconfirmremove: "Note: you are about to remove a template",
  templateconfirmremoveshared: "Note: you are about to remove a public template",
  templaterenameshared: "Note: you are about to rename a public template",
  templateremovesuccess: "Template successfully removed",
  templateremoveerror: "An error occurred while removing the template",
  templatesaveshared: "Note: you are about to change a public template",
  templatesavename: "Provide a name for the template",
  templatesavenameexists: "Provided template name already exists",
  templatesavesuccess: "Template successfully saved",
  templaterenamesuccess: "Template successfully renamed",
  templatesaveerror: "An error occurred while saving the template",
  templaterenameerror: "An error occurred while renaming the template",
  templategetlisterror: "An error occured while retrieving the template list",
  pivotgriddataerror: "An error occured while retrieving pivotgrid data",
  pivotgridfieldserror: "An error occured while retrieving pivotgrid fields settings",
  filtersaveerror: "An error occurred while saving the current filter settings",  
  gridsaveerror: "An error occurred while saving the grid settings",
  newtemplate: "<New>",
  gridorderdate: "Order date",
  gridorderyearmonth: "Order year-month",
  gridorderyearweek: "Order year-week",
  gridorderweeknumber: "Order weeknumber",
  gridorderyear: "Order year",
  gridordermonth: "Order month",
  gridorderday: "Order day",
  gridapproveddate: "Approved date",
  gridapprovedyearmonth: "Approved year-month",
  gridapprovedyearweek: "Approved year-week",
  gridapprovedweeknumber: "Approved week number",
  gridapprovedyear: "Approved year",
  gridapprovedmonth: "Approved month",
  gridapprovedday: "Approved day",
  gridmodel: "Model",
  gridmodelyear: "Model year",
  gridmodelmodelyear: "Model-model year",
  gridregion: "Region",
  gridregionid: "Region ID",
  griddealer: "Dealer",
  griddealerid: "Dealer ID",
  griddealernumber: "Dealer number",
  gridcount: "Count",
  gridcreationdate: "Creation date",
  gridcreationweeknumber: "Creation week number",
  gridcreationyearmonth: "Creation year-month",
  gridcreationyear: "Creation year",
  gridcreationmonth: "Creation month",
  gridcreationweek: "Creation week",
  gridcreationday: "Creation day",
  gridcreationyearweek: "Creation year-week",
  gridfirstcontact: "First contact",
  gridfinisheddate: "Finished date",
  gridfinishedweeknumber: "Finished week number",
  gridfinishedyearmonth: "Finished year-month",
  gridfinishedyearweek: "Finished year-week",
  gridfinishedyear: "Finished year",
  gridfinishedmonth: "Finished month",
  gridfinishedday: "Finished day",
  gridleadbron: "Leadsource",
  gridLeverancier: "Supplier",
  gridopenfinished: "Open/finished",
  gridlocation: "Location",
  gridlocationid: "Location ID",
  gridrelatietypeid: "Relation type ID",
  gridrelatietype: "Relation type",
  gridleadtype: "Lead type",
  gridleadtypeid: "Lead type ID",
  gridcarkind: "Kind of car",
  gridcarkindid: "Kind of car ID",
  gridleadkind: "Kind of lead",
  gridleadkindid: "Kind of lead ID",
  gridthroughtimefirstcontact: "Throughtime until first contact (min)",
  griddeadlineimporter: "Deadline importer",
  griddeadlinemade: "Deadline made",
  hasoffertetrue: "With offer",
  hasoffertefalse: "Without offer",
  hasordertrue: "With order",
  hasorderfalse: "Without order",
  ordersoortnieuw: "New",
  ordersoortoccasion: "Occasion",
  ordersoortvoorraad: "Inventory",
  ordersoortonbekend: "Unknown",
  gridordersoort: "Order car kind",
  gridordersoortvalue: "Order car kind value",
  leadresultaatid: "Lead result ID",
  leadresultaat: "Lead result",
  hasofferteid: "Has offer ID",
  hasofferte: "Has offer",
  hasorderid: "Has order ID",
  hasorder: "Has order",
  gewenstmodel: "Model",
  relatieparticulier: "Private",
  relatiezakelijk: "Business",
  relatielease: "Lease",
  gridMerk: "Make",
  alllgridMerk: "All makes",
  source: "Source",
  yes: "Yes",
  no: "No",
  soortGebruiker: "User type",
  none: "None",
  selectdatum: "Date selection",
  datumaangemaakt: "Lead created",
  datumafgerond: "Lead closed",
  infoopenleads: "This report includes all leads that have not been closed, regardless of the selected period.",
  dealerleads: "Dealer leads",
  relative: "Count %",
  percentageColumnTotal: "Percent of column total",
  percentageRowTotal: "Percent of row total",
  percentagegrandTotal: "Percent of grand total"
};
