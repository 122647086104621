import Vue from "vue";
import * as Vuex from "vuex";
import {
  DefineGetters,
  DefineMutations,
  DefineActions,
  Dispatcher,
  Committer,
} from "vuex-type-helper";
import axios from "axios";

import jwt_decode from "jwt-decode";
import includes from "lodash-es/includes";
import isEmpty from "lodash-es/isEmpty";
import { Autorisatie } from "../Autorisatie";

export interface ApplicationState {
  importeurNaam: string | null;
  importeurCode: string | null;
  importeurMerken: string[] | null;
  groepNamen: string[] | null;
  regioAlias: string | null;
  locale: string;
  jwt: string | null;
  zoneFields: string[];
}

export interface ApplicationGetters {
  importeurNaam: string | null;
  importeurMerken: string[];
  locale: string;
  jwt: string | null;
  tokenIsValid: () => boolean;
  tokenIsOutdated: () => boolean;
  hasAutorisatie: (autorisatie: number) => boolean;
  isAdmin: boolean;
  userId: () => string;
  defaultPage: () => string;
  multipleMakes: () => boolean;
  groupsEnabled: () => boolean;
  groupSingle: (locale: string) => string;
  groupMultiple: (locale: string) => string;
  regionAlias: () => string | null;
  zoneUser: boolean;
  userGroups: string[];
  zoneFields: string[];
}

export interface ApplicationMutations {
  updateImporteur: {
    naam: string;
    code: string;
    merken: string[];
    groepen: string[];
    alias: string | null;
  };
  updateLocale: {
    locale: string;
  };
  updateJWT: {
    jwt: string;
  };
}

export interface ApplicationActions {
  /*incAsync: {
    amount: number;
    delay: number;
  };*/
  initStore: {};
  initImporteur: {};
  initLocale: {};
  initJWT: {};
  setLocale: {
    locale: string;
  };
  setJWT: {
    jwt: string;
  };
  renewTokenIfOutdated: {};
}

/**
 * Implement the module
 */
const state: ApplicationState = {
  importeurNaam: null,
  importeurCode: null,
  importeurMerken: [],
  groepNamen: [],
  regioAlias: null,
  locale: "nl",
  jwt: null,
  zoneFields: ["Zone", "Autobedrijf"],
};

const getters: DefineGetters<ApplicationGetters, ApplicationState> = {
  importeurNaam: (state) => state.importeurNaam,
  zoneFields: (state) => state.zoneFields,
  importeurMerken: (state) =>
    !state.importeurMerken || isEmpty(state.importeurMerken)
      ? []
      : state.importeurMerken.sort(),
  locale: (state) => state.locale,
  jwt: (state) => state.jwt,
  tokenIsOutdated: (state) => {
    return () => {
      // method style omdat getters default cached zijn, met method style zijn ze niet cached.
      if (!!state.jwt) {
        try {
          const decoded: any = jwt_decode(state.jwt);
          const current_time = Math.floor(Date.now() / 1000);
          const renewAfter = 15 * 60; // 15 min

          return decoded.iat + renewAfter <= current_time;
        } catch {
          return false;
        }
      }
      return false;
    };
  },
  multipleMakes: (state) => {
    return () => {
      return (
        !!state.importeurMerken &&
        !isEmpty(state.importeurMerken) &&
        state.importeurMerken.length > 1
      );
    };
  },
  groupsEnabled: (state) => {
    return () => {
      return !!state.groepNamen && !isEmpty(state.groepNamen)
    }
  },
  groupSingle: (state) => {
    return (locale : string) => {
      if (!!state.groepNamen){
      switch (locale)
        {
          case "en":
            if (state.groepNamen.length > 2)
              return state.groepNamen[2];
          default:
          case "nl":
            return state.groepNamen[0];
        }
      }
      return "";
    }
  },
  groupMultiple: (state) => {
    return (locale: string) => {
      if (!!state.groepNamen){
        switch (locale)
          {
            case "en":
              if (state.groepNamen.length > 3)
                return state.groepNamen[3];
            default:
            case "nl":
              if (state.groepNamen.length == 1)
                return state.groepNamen[0];
              return state.groepNamen[1];
          }
        }
      return "";
    }
  },
  regionAlias: () => {
    return () => state.regioAlias
  },
  zoneUser: (state) => {
    try {
      if (!!state.jwt) {
        let decoded = jwt_decode(state.jwt) as any;

        return decoded.gsrt === "Z";
      }
    } catch (error) {}
    return true; // zoneUser is default
  },
  tokenIsValid: (state) => {
    const extraAllowedOvertime = 300 * 60;
    return () => {
      try {
        if (!!state.jwt) {
          const decoded = jwt_decode(state.jwt) as any;
          const current_time = Math.floor(Date.now() / 1000);

          return (
            decoded.exp + extraAllowedOvertime >= current_time && !!decoded.aut
          );
        }
      } catch {
        return false;
      }

      return false;
    };
  },
  hasAutorisatie: (state) => {
    return (autorisatie: number) => {
      if (!!state.jwt && !!state.importeurCode) {
        try {
          let decoded = jwt_decode(state.jwt) as any;
          let aut = decoded.aut;

          let autorisaties = aut
            .split(",")
            .filter(function(item: string) {
              return item.indexOf(state.importeurCode + "-") > -1;
            })
            .map(function(item: string) {
              var nr = item.substring(item.indexOf("-") + 1);
              return parseInt(nr, 10);
            });

          return decoded.adm || includes(autorisaties, autorisatie);
        } catch {
          return false;
        }
      }
      return false;
    };
  },
  isAdmin: (state) => {
    try {
      if (!!state.jwt) {
        let decoded = jwt_decode(state.jwt) as any;
        return !!decoded.adm;
      }
    } catch (error) {}
    return false;
  },
  userId: (state) => {
    try {
      if (!!state.jwt) {
        let decoded = jwt_decode(state.jwt) as any;

        return decoded.usr;
      }
    } catch (error) {}
    return "";
  },
  userGroups: (state) => {
    try {
      if (!!state.jwt) {
        let decoded = jwt_decode(state.jwt) as any;

        return decoded.grpn.split(',');
      }
    } catch (error) {}
    return [];
  },
  defaultPage: (state, getters) => {
    return () => {
      let routerName = "logout";
      if (getters.hasAutorisatie(Autorisatie.GebruikerInloggen)) {
        if (getters.hasAutorisatie(Autorisatie.LeadDashboard)) {
          routerName = "leads";
        } else if (getters.hasAutorisatie(Autorisatie.DashboardOrders)) {
          routerName = "orders";
        } else if (
          getters.hasAutorisatie(Autorisatie.RapportageLeads) ||
          getters.hasAutorisatie(Autorisatie.RapportageOrders)
        ) {
          routerName = "reports";
        } else if (getters.hasAutorisatie(Autorisatie.BeheerUsers)) {
          routerName = "users";
        } 
      }
      return routerName;
    };
  },
};

const mutations: DefineMutations<ApplicationMutations, ApplicationState> = {
  updateImporteur(state, { naam, code, merken, groepen, alias }) {
    state.importeurNaam = naam;
    state.importeurCode = code;
    state.importeurMerken = merken;
    state.groepNamen = groepen;
    state.regioAlias = alias;
  },
  updateLocale(state, { locale }) {
    state.locale = locale;
  },
  updateJWT(state, { jwt }) {
    state.jwt = jwt;
  },
};

const isLocalhost = () => {
  //voor de ontwikkelaars
  const host = window.location.host;
  return host.indexOf("localhost") !== -1;
};

const getPort = () => {
  return location.port;
};

function createCookie(name: string, value: string, days: number) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name: string) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  createCookie(name, "", -1);
}

const cookiestoreJWT = (jwt: string) => {
  const key = isLocalhost() ? "JWT" + getPort() : "JWT";

  if (!!jwt) {
    createCookie(key, jwt, 1);
  } else {
    eraseCookie(key);
  }
};

const actions: DefineActions<
  ApplicationActions,
  ApplicationState,
  ApplicationMutations,
  ApplicationGetters
> = {
  initStore({ dispatch }) {
    dispatch({
      type: "initImporteur",
    });

    dispatch({
      type: "initLocale",
    });

    dispatch({
      type: "initJWT",
    });
  },
  initImporteur({ commit }) {
    axios
      .get("/api/backend/v1/identity/importeur")
      .then((response) => {
        if (!!response && !!response.data) {
          let argpayload = {
            naam: response.data.Importeur,
            code: response.data.ImporteurCode,
            merken: response.data.Merken,
            groepen: response.data.GroepNamen,
            alias: response.data.RegioAlias,
          };

          commit("updateImporteur", argpayload);
        }
      })
      .catch((error) => {});
  },
  initLocale({ commit }) {
    //uit cookie halen:
    const key = isLocalhost() ? "lang" + getPort() : "lang";
    let localeFromCookie = readCookie(key) || "nl";

    let argpayload = { locale: localeFromCookie };
    commit("updateLocale", argpayload);
  },
  setLocale({ commit }, payload) {
    const key = isLocalhost() ? "lang" + getPort() : "lang";
    createCookie(key, payload.locale, 180);

    commit("updateLocale", payload);
  },
  initJWT({ commit }) {
    //uit cookie halen:
    const key = isLocalhost() ? "JWT" + getPort() : "JWT";
    let jwtfromCookie = readCookie(key) || "";
    let argpayload = { jwt: jwtfromCookie };
    commit("updateJWT", argpayload);
  },
  setJWT({ commit }, payload) {
    cookiestoreJWT(payload.jwt);
    commit("updateJWT", payload);
  },
  renewTokenIfOutdated({ commit, getters }) {
    if (getters.tokenIsOutdated()) {
      axios
        .get("/api/backend/v1/identity/renewtoken")
        .then((response) => {
          if (
            !!response &&
            !!response.data &&
            !!response.data.Result &&
            !!response.data.JWT &&
            !!response.data.JWT.Token
          ) {
            cookiestoreJWT(response.data.JWT.Token);
            commit("updateJWT", { jwt: response.data.JWT.Token });
          }
        })
        .catch((error) => {});
    }
  },
};

Vue.use(Vuex);

/**
 * Create a store as same as the ordinary way
 */
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
} as any);

/**
 * Annotate dispatch/commit type with declared actions/mutations type
 
store.dispatch<Dispatcher<ApplicationActions>>({
  type: "incAsync",
  amount: 1,
  delay: 1000
});

store.commit<Committer<ApplicationMutations>>({
  type: "inc",
  amount: 1
});
*/

export default store;
