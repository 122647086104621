import "./lib/classList.js";
import "./lib/closest.js";

import Vue from "vue";
import VueI18n from "vue-i18n";
import axios, { InternalAxiosRequestConfig } from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";
import messages from "./lang"; 
import { ApplicationActions } from "./store";
import { Dispatcher } from "vuex-type-helper";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "nl",
  fallbackLocale: "nl",
  messages: messages
});

router.beforeEach((to, from, next) => {
  if (!!to && !!to.name && to.name === "logout") {
    next();
  } else {
    store.dispatch<Dispatcher<ApplicationActions>>({
      type: "renewTokenIfOutdated"
    });
    next();
  }
});

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url !== undefined && config.headers !== undefined && !!store.getters.jwt) {
      config.headers["Authorization"] = "Bearer " + store.getters.jwt;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error: any) => {
  if (error.response && error.response.status === 401) {
    console.log("401 => logout");
    router.push({ name: "logout" });
  }
  return Promise.reject(error);
});

new Vue({
  i18n,
  router,
  store,
  render: (h: any) => h(App)
}).$mount("#app");
