export default {
  configuration: "Configuratie",
  switchLanguage: "Wijzig taal",
  changepassword: "Wijzig wachtwoord",
  menuoverzicht: "Mijn overzicht",
  beheer: "Beheer",
  searchPlaceholder: "Zoeken...",
  email: "E-mailadres",
  emailshort: "E-mail",
  name: "Naam",
  password: "Wachtwoord", 
  changepasswordold: "Oud wachtwoord",
  changepasswordnew: "Nieuw wachtwoord",
  changepasswordconfirm: "Bevestig nieuw wachtwoord",
  logininvalid: "E-mail of wachtwoord is onjuist",
  login: "Inloggen", 
  details: "Details",
  select: "Selecteer",
  sortMostRecent: "Meest recent",
  sortTitle: "Titel",
  filter: "Filters",
  save: "Opslaan",
  cancel: "Annuleren",
  active: "Actief",
  minutes: "minuten",
  hours: "uren",
  days: "dagen",
  weeks: "weken",
  months: "maanden",
  minute: "minuut",
  hour: "uur",
  day: "dag",
  week: "week",
  month: "maand",
  close: "Sluiten",
  undo: "ongedaan maken",
  insert: "Invoegen",
  collapse: "Inklappen",
  expand: "Uitklappen",
  send: "Verzenden",
  logout: "Uitloggen",
  application: "Applicatie",
  settings: "Instellingen",
  rapportage: "Rapportage",
  dashboard: "Dashboard",
  period: "Periode",
  fromperiod: "Vanaf",
  toperiod: "Tot en met",
  compareperioddata: "Vergelijk met een andere periode",
  fromperiodcompare: "Vanaf",
  toperiodcompare: "Tot en met",
  region: "Regio",
  regions: "Regio's",
  allregion: "Alle regio's",
  autobedrijf: "Autobedrijf",
  allautobedrijf: "Alle autobedrijven",
  branch: "Vestiging",
  allbranch: "Alle vestigingen",
  nobranch: "Geen vestiging",
  leadkind: "Soort lead",
  allleadkinds: "Alle soorten leads",  
  leadtype: "Lead type",
  allleadtypes: "Alle leadtypes",
  widget1: "Sales<br>open leads",
  widget2: "Sales<br>conversieratio",
  widget3: "Aftersales<br>open leads",
  widget4: "Aftersales<br>conversieratio",
  prefixConversion: "Landelijk ",
  open: "Open",
  new: "Nieuw",
  inbehandeling: "In behandeling",
  verstreken: "Deadline verstreken",
  afgerond: "Afgerond",
  succesvol: "Succesvol",
  verwijderd: "Verwijderd",
  gemist: "Gemist",
  conversieratio: "Conversieratio",
  conversieratiosmall: "Conversie ratio",
  leads: "Leads",
  leadsgrid: "Leads grid",
  orders: "Orders",
  pivotgrid: "Draaitabel",
  aantalorders: "Aantal orders",
  orderratio: "Orderratio",
  orderratiosmall: "Order ratio",
  deadlinegehaald: "Deadline gehaald",  
  deadlinegehaaldsmall: "Deadl. geh.",  
  total: "Totaal",
  beheertitel: "Gebruikers",
  userdetailitel: "Gebruiker details",
  showinactiveusers:"Toon inactief",
  autorisatie:"Autorisatie",
  timefilters: {
    custom: "Vrije periode",
    today: "Vandaag",
    yesterday: "Gisteren",
    thisweek: "Deze week",
    lastweek: "Vorige week",
    thismonth: "Deze maand",
    lastmonth: "Vorige maand",
    thisquarter: "Dit kwartaal",
    lastquarter: "Vorige kwartaal",
    thisyear: "Dit jaar",
    lastyear: "Vorig jaar"
  },
  administrator:"Admin",
  passwordTooWeak: "Wachtwoord is te zwak",
  passwordTooShort: "Wachtwoord is te kort (min. 8 karakters)",
  invalidinput: "Onjuiste velden ingevoerd",
  uniqueInput: "E-mailadres is al  in gebruik",
  generalerror: "Onbekende fout opgetreden",
  emailInvalid: "E-mailadres is niet valide",
  passwordIncorrect:"Wachtwoord is onjuist",  
  passwordInequal:"Wachtwoorden zijn ongelijk", 
  models:"Model", 
  modelyear:"Modeljaar", 
  allmodels:"Alle modellen", 
  allmodelyears:"Alle modeljaren", 
  dealers:"Dealer",
  alldealers:"Alle dealers",  
  noauthorizations:"Onvoldoende autorisaties",
  rapportageleads:"Rapportage leads",
  rapportageorders:"Rapportage orders",
  rapportagedealerleads:"Rapportage dealerleads",
  leadtypereport:"Lead type",
  leadtypesales:"Sales",
  leadtypeaftersales:"Aftersales",
  leadtypeboth:"Alle lead typen",
  downloadstart: "Uw rapportage wordt nu opgehaald. Indien het downloaden niet automatisch start:",
  downloadtext: "klik hier om de rapportage te downloaden.",
  requestreport: "Rapportage opvragen",
  unknown: "Onbekend",
  carkind: "Autosoort",
  allcarkinds: "Alle autosoorten",
  infoautosoorten: "Het gaat hierbij om de gewenste auto soort en dit filter heeft alleen invloed op de sales data.",
  autosoorten: {
    unknown: "Onbekend",
    new: "Nieuw",   
    occasion: "Occasion"
  },
  leadtypes: {
    unknown: "Onbekend",
    sales: "Sales",   
    aftersales: "Aftersales"
  },
  templatetext: "Weergave",
  selecttemplate: "Selecteer weergave...",
  publicmessage: "Is publiek",
  renametemplate: "Hernoemen",
  removetemplate: "Verwijderen",
  savetemplate: "Opslaan",
  saveastemplate: "Opslaan als",
  ownedprivate: "Eigen privé weergaven",
  ownedshared: "Eigen publieke weergaven",
  notownedshared: "Gedeelde publieke weergaven",
  templateconfirm: "Bevestig",
  templateconfirmremove: "Let op: u staat op het punt om een weergave te verwijderen",
  templateconfirmremoveshared: "Let op: u staat op het punt om een publieke weergave te verwijderen",
  templaterenameshared: "Let op: u staat op het punt om een publieke weergave te hernoemen",
  templateremovesuccess: "Weergave succesvol verwijderd",
  templateremoveerror: "Er is een fout opgetreden tijdens het verwijderen van de weergave",
  templatesaveshared: "Let op: u staat op het punt om een publieke weergave te wijzigen",
  templatesavename: "Geef een naam voor de weergave op",
  templatesavenameexists: "Opgegeven weergave naam bestaat reeds",
  templatesavesuccess: "Weergave is succesvol opgeslagen",
  templaterenamesuccess: "Weergave succesvol hernoemd",
  templatesaveerror: "Er is een fout opgetreden tijdens het opslaan van de weergave",
  templaterenameerror: "Er is een fout opgetreden tijdens het hernoemen van de weergave",
  templategetlisterror: "Er is een fout opgetreden tijdens het ophalen van de weergave lijst",  
  pivotgriddataerror: "Er is een fout opgetreden tijdens het ophalen van de draaitabel data",
  pivotgridfieldserror: "Er is een fout opgetreden tijdens het ophalen van de draaitabel velden configuratie",
  filtersaveerror: "Er is een fout opgetreden tijdens het opslaan van de huidige filter instellingen",  
  gridsaveerror: "Er is een fout opgetreden tijdens het opslaan van de huidige grid instellingen",
  newtemplate: "<Nieuw>",
  gridorderdate: "Order datum",
  gridorderyearmonth: "Order jaar-maand",
  gridorderyearweek: "Order jaar-week",
  gridorderweeknumber: "Order weeknummer",
  gridorderyear: "Order jaar",
  gridordermonth: "Order maand",
  gridorderday: "Order dag",
  gridapproveddate: "Goedgekeurd datum",
  gridapprovedyearmonth: "Goedgekeurd jaar-maand",
  gridapprovedyearweek: "Goedgekeurd jaar-week",
  gridapprovedweeknumber: "Goedgekeurd weeknummer",
  gridapprovedyear: "Goedgekeurd jaar",
  gridapprovedmonth: "Goedgekeurd maand",
  gridapprovedday: "Goedgekeurd dag",
  gridmodel: "Model",
  gridmodelyear: "Modeljaar",
  gridmodelmodelyear: "Model-modeljaar",
  gridregion: "Regio",
  gridregionid: "Regio ID",
  griddealer: "Autobedrijf",
  griddealerid: "Autobedrijf ID",
  griddealernumber: "Dealernummer",
  gridcount: "Aantal",
  gridcreationdate: "Aangemaakt datum",
  gridcreationweeknumber: "Aangemaakt weeknummer",
  gridcreationyearmonth: "Aangemaakt jaar-maand",
  gridcreationyear: "Aangemaakt jaar",
  gridcreationmonth: "Aangemaakt maand",
  gridcreationweek: "Aangemaakt week",
  gridcreationday: "Aangemaakt dag",
  gridcreationyearweek: "Aangemaakt jaar-week",
  gridfirstcontact: "Eerste contact",
  gridfinisheddate: "Afgerond datum",
  gridfinishedweeknumber: "Afgerond weeknummer",
  gridfinishedyearmonth: "Afgerond jaar-maand",
  gridfinishedyearweek: "Afgerond jaar-week",
  gridfinishedyear: "Afgerond jaar",
  gridfinishedmonth: "Afgerond maand",
  gridfinishedday: "Afgerond dag",
  gridleadbron: "Leadbron",
  gridLeverancier: "Leverancier",
  gridopenfinished: "Open/afgerond",
  gridlocation: "Vestiging",
  gridlocationid: "Vestiging ID",
  gridrelatietypeid: "Relatiesoort ID",
  gridrelatietype: "Relatiesoort",
  gridleadtype: "Lead type",
  gridleadtypeid: "Lead type ID",
  gridcarkind: "Autosoort",
  gridcarkindid: "Autosoort ID",
  gridleadkind: "Soort lead",
  gridleadkindid: "Soort lead ID",
  gridthroughtimefirstcontact: "Doorlooptijd tot eerste contact (min)",
  griddeadlineimporter: "Deadline importeur",
  griddeadlinemade: "Deadline gehaald",
  hasoffertetrue: "Met offerte",
  hasoffertefalse: "Zonder offerte",
  hasordertrue: "Met order",
  hasorderfalse: "Zonder order",
  ordersoortnieuw: "Nieuw",
  ordersoortoccasion: "Occasion",
  ordersoortvoorraad: "Voorraad",
  ordersoortonbekend: "Onbekend",
  gridordersoort: "Order autosoort",
  gridordersoortvalue: "Order autosoort value",
  leadresultaatid: "Lead resultaat ID",
  leadresultaat: "Lead resultaat",
  hasofferteid: "Heeft offerte ID",
  hasofferte: "Heeft offerte",
  hasorderid: "Heeft order ID",
  hasorder: "Heeft order",
  gewenstmodel: "Model",
  relatieparticulier: "Particulier",
  relatiezakelijk: "Zakelijk",
  relatielease: "Lease",
  gridMerk: "Merk",
  alllgridMerk: "Alle merken",
  source: "Bron",
  yes: "Ja",
  no: "Nee",
  soortGebruiker: "Soort gebruiker",
  none: "Geen",
  selectdatum: "Datumselectie",
  datumaangemaakt: "Lead aangemaakt",
  datumafgerond: "Lead afgerond",
  infoopenleads: "Deze rapportage bevat ook alle leads die nog niet zijn afgerond, ongeacht de geselecteerde periode.",
  dealerleads: "Dealerleads",
  relative: "Aantal %",
  percentageColumnTotal: "Percentage van kolom totaal",
  percentageRowTotal: "Percentage van rij totaal",
  percentagegrandTotal: "Percentage van eindtotaal"
};
