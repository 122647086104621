export enum Autorisatie {
    GebruikerInloggen = 1,
    SysteemImporteur = 2,
    LeadDashboard = 3,
    BeheerUsers = 4,
    DashboardOrders = 5,
    RapportageLeads = 6,
    RapportageOrders = 7,
    PivotGridSettingsPublic = 8,
    RapportageDealerleads = 9
 }